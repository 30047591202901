// Package
import styled from 'styled-components'
import { Form } from 'antd'

interface DatesReportProps {
  isMonth: any
  isDay: any
}

export const StyledFilterReportContainer = styled(Form)`
  width: calc(100vw - 250px) !important;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 76px;
  right: 0;
  @media (max-width: 1024px) {
    width: calc(100vw - 1px) !important;
    position: relative;
    top: 0;
  }
`

export const StyledDatesReportContainer = styled.div<DatesReportProps>`
  width: 90%;
  display: flex;
  justify-content: ${({ isMonth, isDay }) =>
    isMonth || isDay ? 'start' : 'center'};
  align-items: center;
  & .ant-form-item {
    width: 87%;
    margin-bottom: 0;
  }
  .ant-form-item
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    #DatePickerSalesReport {
    padding-right: 9px;
  }
`

export const StyledReportTitle = styled.h1`
  width: 159px;
  height: 19px;
  margin: 0;
  margin: 0 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`
export const StyledSeparator = styled.span`
  position: relative;
  text-align: center;
  top: 10px;
  font-size: 30px;
  border-left: 1px solid;
  width: 1px;
  height: 40px;
  color: #d2d2d2;
  right: 4vw;
  @media (min-width: 576px) {
    right: 2vw;
  }
`
