import React from 'react'

import { Col, Row } from 'antd'

import {
  CardBeesContainer,
  CardContainer,
  StyledFontLeft,
  StyledFontRight
} from './styledComponents'

interface RowProps {
  parameter?: React.ReactNode
  value?: React.ReactNode
}

export interface TileRowsProps {
  rowBody: RowProps[]
  theme?: string
}

export const RowBody: React.FC<RowProps> = ({ parameter, value }) => (
  <>
    <Col xs={16} sm={16} md={18} lg={18} xl={20}>
      <StyledFontLeft>{parameter}</StyledFontLeft>
    </Col>
    <Col xs={8} sm={8} md={6} lg={6} xl={4}>
      <StyledFontRight>{value}</StyledFontRight>
    </Col>
  </>
)

export const RowBodyBees: React.FC<RowProps> = ({ parameter, value }) => (
  <>
    <>
      <Col xs={10} sm={12} md={16} lg={16} xl={20}>
        <StyledFontLeft>{parameter}</StyledFontLeft>
      </Col>
      <Col xs={14} sm={12} md={8} lg={8} xl={4}>
        <StyledFontRight>{value}</StyledFontRight>
      </Col>
    </>
  </>
)

export const CardRowsBees: React.FC<TileRowsProps> = ({ rowBody, theme }) => {
  return (
    <CardBeesContainer className={theme}>
      <Row gutter={[8, 12]}>
        {rowBody.map((rowBodyItem, index) => (
          <RowBodyBees {...rowBodyItem} key={index} />
        ))}
      </Row>
    </CardBeesContainer>
  )
}

const TileRows: React.FC<TileRowsProps> = ({ rowBody, theme }) => {
  return (
    <CardContainer className={theme}>
      <Row gutter={[8, 12]}>
        {rowBody.map((rowBodyItem, index) => (
          <RowBody {...rowBodyItem} key={index} />
        ))}
      </Row>
    </CardContainer>
  )
}

export default TileRows
