import styled from 'styled-components'

export const CardContainer = styled.div`
  width: 100%;
  position: relative;
  top: 45px;
  margin: 6px 0 5px;
  padding: 2% 10%;
  background-color: #fff;
  font-size: 12px;
  &.report-by-day {
    margin: 0 0 20px;
    padding: 0;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding: 3% 7%;
    top: 0px;
  }
  @media (max-width: 576px) {
    margin: 5px 0;
    top: 0px;
  }
  @media all and (max-width: 480px) {
    padding: 5%;
    top: 0px;
  }
`

export const CardBeesContainer = styled.div`
  width: 100%;
  position: relative;
  top: 0px;
  margin: 6px 0 5px;
  padding: 2% 20%;
  font-size: 12px;
  &.report-by-day {
    margin: 0 0 20px;
    padding: 0;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding: 5% 8%;
  }
  @media (max-width: 576px) {
    margin: 5px 0;
  }
  @media all and (max-width: 480px) {
    padding: 5%;
  }
`

export const StyledFontRight = styled.div`
  font-weight: 600;
  text-align: right;
  color: #000;
`
export const StyledFontLeft = styled.div`
  text-align: left;
  color: #000;
`
