import React from 'react'
import SearchEngineOptimization from '../../../../components/SEO'
import LayoutMain from '../../../../layout'
import WithPrivateRoute from '../../../../higherOrderComponents/WithPrivateRoute'
import { BeesPayReportByDays } from '../../../../components/BeesPayReportByDays'

const DetailsByDays: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="BEES Pay por días" />
      <WithPrivateRoute component={BeesPayReportByDays} />
    </LayoutMain>
  )
}

export default DetailsByDays
